import React from "react"

import Layout from "../components/Layout/layout"
import SEO from "../components/SEO/seo"
import { Text } from "../components/Text/Text"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div style={{
      padding: '4em 0'
    }}>
      <h1>NOT FOUND</h1>
      <Text>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </Text>
    </div>
  </Layout>
)

export default NotFoundPage
